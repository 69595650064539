/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    pre: "pre",
    code: "code",
    h2: "h2",
    h3: "h3",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A javascript async-await is syntactical sugar for promises."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Simple Promise Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  })), "\n", React.createElement(_components.h2, null, "Gatchas"), "\n", React.createElement(_components.h3, null, React.createElement(_components.code, null, ".forEach")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "forEach"), " results in stange behavior with async await and it is better to just use ", React.createElement(_components.code, null, "for .. of")), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://javascript.info/async-await"
  }, "async await - javascript.info")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
